html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  margin: 0; }

h1, h2, h3, h4, h5, p {
  margin-top: 0; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

figure {
  margin: 0;
  display: inline-block; }

@font-face {
  font-family: 'Velux Gothic';
  src: url("/fonts/VeluxGothic-Bold_WebTT.otf?#iefix") format("embedded-opentype"), url("/fonts/VeluxGothic-Bold_WebTT.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Velux Gothic';
  src: url("/fonts/VeluxGothic-Regular_WebTT.otf?#iefix") format("embedded-opentype"), url("/fonts/VeluxGothic-Regular_WebTT.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

.text,
body {
  font-size: 1rem;
  line-height: 1.63; }
  .text--small {
    font-size: 0.875rem;
    line-height: 1.57; }

body {
  font-family: 'Velux Gothic', Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 1.625rem;
  letter-spacing: normal;
  font-smoothing: antialiased;
  color: #333333; }

h1 {
  font-size: 4.5rem;
  line-height: 4.75rem;
  font-weight: 400; }

h2 {
  font-size: 2.25rem;
  line-height: 2.625rem;
  font-weight: 400; }

h3 {
  font-size: 1.5625rem;
  line-height: 1.875rem;
  font-weight: 700; }

p {
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  word-break: break-word; }

.small, .plan__cta-small, .plan__some-label,
small {
  margin-bottom: 0.25rem;
  font-size: 0.9375rem;
  line-height: 1.125rem; }

a {
  padding-bottom: 1px;
  border-bottom: 1px solid grey;
  display: inline-block;
  text-decoration: none;
  word-break: break-word;
  transition: all 0.25s ease; }

button {
  box-shadow: none;
  cursor: pointer;
  transition: all 0.25s ease;
  font-family: inherit; }

.btn {
  height: 4rem;
  padding: 0 0.4375rem;
  border-radius: 4px;
  border: 1px solid #ff0000;
  margin-bottom: 0.5rem;
  display: inline-block;
  outline: none;
  background-color: #ff0000;
  font-family: 'Velux Gothic', Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: bold;
  color: white;
  word-break: break-word; }
  .btn__inner {
    display: table-cell;
    text-align: center;
    vertical-align: middle; }
  .btn--active, .btn:hover {
    background-color: #e11619; }
  .btn:active, .btn--active:active, .btn:hover:active {
    background-color: #d51417; }
  .btn:disabled {
    background-color: #eeeeee;
    border-color: #eeeeee;
    color: #9a9a9a;
    cursor: default; }
  @media screen and (max-width: 40em) {
    .btn--nohover:active, .btn--nohover:hover {
      background-color: #ff0000; } }
  .btn__plain {
    border-color: transparent;
    padding: 0;
    background: transparent;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #9a9a9a;
    outline: none; }
    .btn__plain:active, .btn__plain:hover {
      color: #333333; }
  .btn__secondary {
    border-color: #ff0000;
    background-color: white;
    color: #ff0000; }
    .btn__secondary--active {
      color: #ff0000; }
    .btn__secondary--active, .btn__secondary--active:hover, .btn__secondary:hover {
      background-color: rgba(255, 0, 0, 0.1); }
    .btn__secondary:active, .btn__secondary--active:active, .btn__secondary:hover:active {
      background-color: rgba(255, 0, 0, 0.15); }
  .btn__optional {
    border-color: transparent;
    background-color: white;
    color: #333333; }
    .btn__optional--active {
      color: #333333;
      border-color: transparent; }
    .btn__optional--active, .btn__optional--active:hover, .btn__optional:hover {
      color: #333333;
      background-color: #e6e6e6; }
    .btn__optional:active, .btn__optional--active:active, .btn__optional:hover:active {
      background-color: #d9d9d9; }
  .btn__clear-selections {
    width: 9.375rem;
    margin: 0 auto; }
    @media screen and (max-width: 40em) {
      .btn__clear-selections {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1px; } }

body {
  overflow-x: hidden; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

a, input, textarea, button, label, span {
  -webkit-tap-highlight-color: transparent; }

.container {
  width: 100%;
  max-width: 58.75rem;
  margin: 0 auto;
  position: relative; }

.scene {
  overflow: hidden; }
  .scene__background {
    height: 53%;
    margin-bottom: 1rem;
    background-color: #eeeeee; }
  .scene__container {
    width: 100%;
    max-width: 58.75rem;
    margin: 0 auto; }
  .scene__graphics {
    width: 100%;
    max-width: 62.5rem;
    height: 0;
    padding-bottom: 53%;
    margin: 0 auto;
    position: relative;
    overflow: hidden; }
  .scene__empty {
    padding-bottom: 53%;
    background-color: #eeeeee; }

.stage-background-svg {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute; }

.footer-nav {
  width: calc(100% - 32px);
  max-width: 38.75rem;
  height: 1.1875rem;
  margin-bottom: 2.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  position: relative; }
  @media screen and (max-width: 40em) {
    .footer-nav {
      margin-bottom: 0;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      right: 1rem; } }
  .footer-nav--simple {
    display: block;
    text-align: right; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  animation-name: slideOutLeft; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  animation-name: fadeInUp; }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  animation-name: fadeOutDown; }

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both; }

@media (prefers-reduced-motion) {
  .animated {
    animation: unset !important;
    transition: none !important; } }

.intro .stage-header {
  background-color: transparent; }

.stage-header {
  border-bottom: 1px solid #eeeeee;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; }
  .stage-header--transparent {
    border-bottom: none;
    background-color: transparent; }
  .stage-header__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    padding: 1rem; }

.languages {
  width: 100%;
  margin-bottom: 1rem; }
  .languages li {
    display: inline-block; }
    .languages li:last-of-type {
      margin-left: 0.4375rem; }
    .languages li:last-of-type .languages__item:after {
      display: none; }
  .languages__item {
    padding: 0;
    margin-right: 0.5rem;
    border: 0;
    border-bottom: 1px solid transparent;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: white;
    text-decoration: none;
    background-color: transparent;
    position: relative; }
    .languages__item:after {
      content: '|';
      position: absolute;
      right: -0.5rem;
      top: 0; }
    .languages__item:hover, .languages__item--active {
      border-bottom: 1px solid white; }
    .languages__item:active {
      border-bottom-color: #ff0000; }

.question {
  max-width: 620px;
  margin: 0 auto;
  position: relative; }
  .question.animate-in {
    animation: question-buttons-in 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .question__tip {
    width: 1.875rem;
    height: 1.875rem;
    background: white url("../images/icon-light-bulb.svg") no-repeat center;
    border: 1px solid rgba(154, 154, 154, 0.25);
    border-radius: 100%;
    padding: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: none;
    font-size: 1.125rem;
    line-height: 1.4375rem;
    font-weight: 700;
    position: absolute;
    top: 5.625rem;
    right: 0;
    z-index: 5; }
    @media screen and (max-width: 40em) {
      .question__tip {
        display: block; } }
    .question__tip--active {
      background-image: url("../images/icon-light-bulb-red.svg"); }
    .question__tip .controllers {
      padding-left: 0;
      padding-right: 0; }
  .question__text {
    max-width: 90%;
    min-height: 2.875rem;
    margin-bottom: 1rem; }
    @media screen and (max-width: 40em) {
      .question__text {
        padding-left: 1rem;
        padding-right: 1rem; } }
    .question__text--desktop {
      max-width: 620px;
      margin-bottom: 2rem;
      color: #9a9a9a; }
      @media screen and (max-width: 40em) {
        .question__text--desktop {
          padding-left: 1rem;
          padding-right: 1rem; } }
  .question__pagination {
    width: 100%;
    position: relative;
    margin-bottom: 2.5rem; }
    @media screen and (max-width: 58.75em) {
      .question__pagination {
        margin-bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8.5rem; } }
  .question__pagination-circle {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #FCC5C5;
    border-radius: 0.25rem;
    margin: auto 0.125rem;
    display: inline-block; }
    .question__pagination-circle:first-child {
      margin-left: 0; }
    .question__pagination-circle:last-child {
      margin-right: 0; }
    .question__pagination-circle--active {
      background-color: #ff0000; }

@keyframes question-buttons-in {
  from {
    transform: translateY(50px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

.controllers {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 2rem;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  @media screen and (max-width: 40em) {
    .controllers {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .controllers .btn {
    width: 100%;
    transition: background-color 0.25s ease; }
  .controllers--2 .btn {
    width: calc(100% / 2 - 4px); }
  .controllers--4 .btn {
    width: calc(100% / 4 - 6px); }
    @media screen and (max-width: 58.75em) {
      .controllers--4 .btn {
        width: calc(100% / 2 - 4px); } }

.horizontal-scroll {
  width: calc(100% - 176px);
  margin-bottom: 0.5rem;
  margin-left: 0;
  white-space: normal;
  text-align: left; }
  @media screen and (max-width: 40em) {
    .horizontal-scroll {
      width: calc(100% + 32px);
      margin-left: -1rem;
      white-space: nowrap;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-pack: justify;
          justify-content: space-between;
      -ms-flex-align: center;
          align-items: center; } }
  .horizontal-scroll__inner {
    width: 100%;
    overflow: auto; }
    @media screen and (max-width: 40em) {
      .horizontal-scroll__inner {
        padding-left: 1rem;
        padding-right: 1rem;
        animation: 0.5s ease-out 1s canSlide; } }
  .horizontal-scroll .btn {
    width: 8.75rem; }
  .horizontal-scroll__secondary {
    width: 11rem; }
    .horizontal-scroll__secondary .btn__plain {
      padding: 1.375rem 0.875rem; }
    @media screen and (max-width: 40em) {
      .horizontal-scroll__secondary {
        width: 100%; } }

.figure-counter {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  border: 1px solid #ff0000;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #ff0000;
  position: absolute;
  right: 0;
  bottom: 0; }
  @media screen and (max-width: 40em) {
    .figure-counter {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 0.75rem;
      line-height: 1.25rem; } }

@keyframes canSlide {
  0% {
    padding-left: 1.125rem; }
  50% {
    padding-left: 0; }
  100% {
    padding-left: 1.125rem; } }

.progress-bar {
  width: 3.875rem;
  height: 0.4375rem;
  background-color: #FCC5C5;
  border-radius: 4px;
  margin-top: 0.3125rem;
  display: block;
  position: relative;
  overflow: hidden;
  float: right; }
  .progress-bar > span {
    border-radius: 4px 0 0 4px;
    height: inherit;
    background-color: #ff0000;
    position: absolute;
    top: 0;
    left: 0;
    transition: width .5s ease-out; }

.overlay {
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3; }
  .overlay__inner {
    height: calc(100% - 205px);
    background-color: white;
    padding: 1.5rem 1.25rem;
    overflow: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: self-end;
        align-items: self-end;
    -ms-flex-line-pack: justify;
        align-content: space-between;
    animation: overlayReveal 0.25s ease-out forwards; }
  .overlay .btn {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 2.5rem; }

@keyframes overlayReveal {
  from {
    transform: translateY(130%); }
  to {
    transform: translateY(205px); } }

input {
  width: 100%;
  max-width: 15.9375rem;
  padding: 1.4375rem 1.25rem;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  margin: 0 auto 0.5rem auto;
  display: block;
  background-color: white;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: #333333; }

.address-search {
  width: 100%;
  max-width: 20.9375rem;
  padding: 1.4375rem 1.25rem;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  margin: 0 auto 0.5rem auto;
  display: block;
  background-color: white;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: #333333; }
  .address-search__dropdown {
    width: 100%;
    max-width: 20.9375rem;
    margin: 1.5rem auto 2rem auto;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    background-color: white; }
  .address-search__item {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    border-bottom: 1px solid #eeeeee; }
  .address-search__error {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    color: #ff0000; }
  .address-search__button {
    width: 100%;
    padding: 1.375rem 0;
    border: 0;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    text-align: left;
    background-color: transparent; }

.btn__toggle {
  min-width: 8.75rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  overflow: hidden;
  vertical-align: top; }
  @media screen and (max-width: 40em) {
    .btn__toggle {
      margin-bottom: 0; } }

.btn__toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .btn__toggle-input:checked + .btn__toggle-label {
    background-color: rgba(255, 0, 0, 0.15); }

.btn__toggle-label {
  width: 100%;
  padding: 1.375rem 0.875rem;
  border-radius: 4px;
  border: 1px solid #ff0000;
  display: inline-block;
  outline: none;
  background-color: white;
  cursor: pointer;
  font-family: 'Velux Gothic', Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: bold;
  text-align: center;
  color: #ff0000;
  word-break: break-word; }
  .btn__toggle-label--active {
    color: #ff0000; }
  .btn__toggle-label:hover {
    background-color: rgba(255, 0, 0, 0.1); }
  .btn__toggle-label:active, .btn__toggle-label:hover:active {
    background-color: rgba(255, 0, 0, 0.15); }

.tips-categories {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  text-align: center; }

.tip-category {
  width: 30%;
  max-width: 6.875rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: top; }
  @media screen and (max-width: 40em) {
    .tip-category {
      margin-left: 0.25rem;
      margin-right: 0.25rem; } }
  .tip-category__icon-container {
    position: relative; }
    .tip-category__icon-container:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 10px);
      border-radius: 100px;
      opacity: 0.1;
      z-index: -1;
      background-color: #ff0000; }
  .tip-category__title {
    padding: 0 0.25rem; }
  .tip-category__img {
    margin-bottom: 0.5rem; }

.figure--hidden {
  display: none; }

.cookie-notice {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 0 0.75rem 0 rgba(51, 51, 51, 0.25);
  bottom: -100%;
  transition: bottom .6s ease-in-out;
  font-size: 0.875rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .cookie-notice a {
    color: #ff0000;
    text-decoration: underline;
    border-bottom: 0; }
  @media screen and (max-width: 40em) {
    .cookie-notice {
      -ms-flex-direction: column;
          flex-direction: column; } }
  .cookie-notice--active {
    bottom: 0; }
  .cookie-notice__text-container {
    -ms-flex: 20;
        flex: 20; }
    @media screen and (max-width: 40em) {
      .cookie-notice__text-container {
        margin-bottom: 0.75rem; } }
  .cookie-notice__button-container {
    -ms-flex: 1;
        flex: 1;
    margin-left: 1.5rem; }
    @media screen and (max-width: 40em) {
      .cookie-notice__button-container {
        width: 100%;
        margin-left: 0; } }
    .cookie-notice__button-container .btn {
      min-width: 150px;
      margin-bottom: 0;
      height: 3rem; }
      @media screen and (max-width: 40em) {
        .cookie-notice__button-container .btn {
          width: 100%; } }

.rotate-device-overlay {
  pointer-events: none;
  display: none; }
  @media only screen and (max-width: 940px) and (min-aspect-ratio: 14 / 9) {
    .rotate-device-overlay {
      pointer-events: auto;
      position: fixed;
      z-index: 1000;
      background-color: rgba(0, 0, 0, 0.85);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
      .rotate-device-overlay img {
        margin-bottom: 24px; }
      .rotate-device-overlay__inner {
        -ms-flex: 1;
            flex: 1; } }

.section-intro {
  max-width: 38.75rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-line-pack: center;
      align-content: center; }
  @media screen and (max-width: 58.75em) {
    .section-intro {
      height: 100vh;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      margin: -2.9375rem auto 0 auto;
      text-align: center; } }
  .section-intro__container {
    width: 100%;
    max-width: 58.75rem;
    margin: 0 auto;
    position: relative; }
    @media screen and (max-width: 58.75em) {
      .section-intro__container {
        height: 100%; } }
  .section-intro__title, .section-intro__copy {
    width: 100%;
    color: white; }
  .section-intro__title {
    margin-bottom: 1rem;
    color: white; }
    @media screen and (max-width: 58.75em) {
      .section-intro__title {
        font-size: 2.25rem;
        line-height: 2.625rem; } }
    .section-intro__title--big {
      font-size: 4.5rem;
      line-height: 4.75rem; }
  .section-intro__copy {
    margin-bottom: 4.5rem;
    font-weight: 400; }
    @media screen and (max-width: 58.75em) {
      .section-intro__copy {
        margin-bottom: 0;
        font-size: 1.125rem;
        line-height: 1.4375rem;
        font-weight: normal; } }
  .section-intro__cta {
    width: 100%;
    max-width: 18.75rem;
    margin: 0;
    display: table; }
    .section-intro__cta a {
      border-bottom: 0;
      display: table-cell;
      color: white;
      text-align: center;
      vertical-align: middle; }
    @media screen and (max-width: 58.75em) {
      .section-intro__cta {
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2.875rem; } }

.intro-step {
  margin-top: 9vh;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-align: center;
  overflow: hidden; }
  .intro-step__image-wrapper {
    min-width: 16.875rem;
    min-height: 10rem;
    margin: 9vh auto 1rem auto;
    overflow: hidden; }
  .intro-step__image {
    max-width: inherit;
    max-height: inherit; }
  .intro-step__title, .intro-step__copy {
    max-width: 26.25rem;
    margin-left: auto;
    margin-right: auto; }
  .intro-step__title {
    margin-bottom: 0.25rem; }
  .intro-step__copy {
    margin-bottom: 3.75rem; }
  .intro-step__cta {
    width: 100%;
    max-width: 18.75rem;
    margin: 0 auto;
    display: table; }
    .intro-step__cta a {
      border-bottom: 0;
      display: table-cell;
      color: white;
      text-align: center;
      vertical-align: middle; }
    @media screen and (max-width: 58.75em) {
      .intro-step__cta {
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2.875rem; } }

.location-search {
  margin-top: 9vh;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-align: center;
  overflow: hidden; }
  .location-search__image-wrapper {
    max-width: 16.875rem;
    max-height: 10rem;
    margin: 9vh auto 2rem auto;
    overflow: hidden; }
  .location-search__image {
    max-width: inherit;
    max-height: inherit; }
  .location-search__title, .location-search__copy {
    max-width: 26.25rem;
    margin-left: auto;
    margin-right: auto; }
  .location-search__title {
    margin-bottom: 0.25rem; }
  .location-search__copy {
    margin-bottom: 1.5rem; }
  .location-search__cta {
    width: 100%;
    max-width: 20.9375rem;
    margin: 0 auto;
    display: table; }
    .location-search__cta:disabled {
      display: inline-block; }
    .location-search__cta a {
      border-bottom: 0;
      display: table-cell;
      color: white;
      text-align: center;
      vertical-align: middle; }
    .location-search__cta.btn {
      position: relative;
      text-align: center; }

.scene {
  width: 100%;
  margin-top: 3.75rem;
  transition: left 0.75s ease-in-out; }

.done {
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .done__inner {
    margin: 0 auto 1.25rem auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-line-pack: center;
        align-content: center;
    -ms-flex-pack: center;
        justify-content: center; }
  .done__title {
    width: 100%; }
  .done__img {
    margin: 0 auto 2rem auto; }

.tips-intro {
  margin-top: 7rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .tips-intro__inner {
    width: 100%;
    max-width: 38.75rem;
    margin: 0 auto;
    text-align: center; }
    @media screen and (max-width: 40em) {
      .tips-intro__inner {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .tips-intro__title {
    width: 100%;
    margin-bottom: 2.875rem;
    font-weight: 400; }
    @media screen and (max-width: 40em) {
      .tips-intro__title {
        margin-bottom: 1rem;
        font-size: 1.125rem;
        line-height: 1.4375rem; } }
  .tips-intro__btn, .tips-intro__instructions {
    max-width: 18.75rem;
    margin: 0 auto 1.5rem auto; }
  .tips-intro__btn {
    width: 100%;
    max-width: 100%;
    margin: 0 0 1.5rem 0;
    display: table; }
    .tips-intro__btn a {
      border-bottom: 0;
      display: table-cell;
      color: white;
      text-align: center;
      vertical-align: middle; }
    .tips-intro__btn.btn {
      position: relative;
      text-align: center; }
  .tips-intro__instructions {
    color: #9a9a9a; }

.tips {
  height: 100%;
  margin-top: 6.25rem; }
  .tips__header {
    max-width: 26.25rem;
    margin: 0 auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center; }
  .tips__header-title {
    margin-bottom: 0.5rem; }
  .tips__header-img {
    width: 100%;
    max-width: 2.8125rem;
    margin-bottom: 0.75rem;
    transform: scale(1.8); }
  .tips__header-copy {
    margin-bottom: 2.5rem; }
    @media screen and (max-width: 40em) {
      .tips__header-copy {
        margin-bottom: 0.625rem; } }
  .tips__tips {
    width: 100%;
    margin-bottom: 2.5rem;
    background-color: #eeeeee;
    white-space: nowrap;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    text-align: center; }
    @media screen and (max-width: 40em) {
      .tips__tips {
        margin-bottom: 0;
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0; } }
    @media screen and (max-width: 58.75em) {
      .tips__tips {
        max-height: 14.0625rem; } }
  .tips__inner {
    width: 100%;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    overflow: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; }
    @media screen and (max-width: 58.75em) {
      .tips__inner {
        display: block;
        animation: 0.5s ease-out 2s canSlide; } }
  .tips__tip {
    width: 18.75rem;
    padding: 1rem 1.25rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
    border-radius: 4px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
    background-color: white;
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    position: relative;
    text-align: left; }
    @media screen and (max-width: 58.75em) {
      .tips__tip {
        scroll-snap-align: center;
        max-height: 12.0625rem; }
        .tips__tip:after {
          display: block;
          content: ' ';
          position: absolute;
          bottom: 3.25rem;
          left: 0;
          height: 8px;
          width: 100%;
          background-color: rgba(255, 255, 255, 0);
          background-image: linear-gradient(rgba(255, 255, 255, 0), white); } }
  .tips__text-inner {
    overflow-y: scroll;
    margin-bottom: 2.8125rem;
    position: relative; }
    @media screen and (max-width: 58.75em) {
      .tips__text-inner {
        max-height: 7.8125rem; } }
  .tips__tip-difficulty {
    margin-bottom: 0.25rem;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    color: #9a9a9a;
    text-transform: uppercase; }
  .tips__tip-copy {
    font-size: 1.125rem;
    line-height: 1.4375rem; }
    @media screen and (max-width: 40em) {
      .tips__tip-copy {
        font-size: 0.9375rem;
        line-height: 1.125rem;
        min-height: 5.625rem; } }
  .tips__tip-cta {
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border: none;
    border-top: 1px solid #eeeeee;
    border-radius: 0 0 4px 4px;
    background-color: transparent;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #ff0000;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    outline: none; }
    .tips__tip-cta:hover, .tips__tip-cta:active, .tips__tip-cta--active {
      background-color: #FFD9D9; }
    @media screen and (max-width: 40em) {
      .tips__tip-cta--nohover:active, .tips__tip-cta--nohover:hover {
        background-color: white;
        animation: 0.5s ease-out colorFlash; } }
  .tips__pagination {
    width: 100%;
    margin-bottom: 2.5rem; }
  .tips__pagination-circle {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #FCC5C5;
    border-radius: 0.25rem;
    margin: auto 0.125rem;
    display: inline-block; }
    .tips__pagination-circle:first-child {
      margin-left: 0; }
    .tips__pagination-circle:last-child {
      margin-right: 0; }
    .tips__pagination-circle--active {
      background-color: #ff0000; }
  .tips .question__text--desktop {
    text-align: center; }

@keyframes colorFlash {
  0% {
    background-color: white; }
  50% {
    background-color: #FFD9D9; }
  100% {
    background-color: white; } }

.plan {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-top: 6.25rem; }
  .plan__header {
    max-width: 30.625rem;
    margin: 12.5rem auto 3rem auto;
    text-align: center; }
    @media screen and (max-width: 58.75em) {
      .plan__header {
        margin-top: 2rem; } }
  .plan__header-title {
    margin-bottom: 0.5rem; }
    @media screen and (max-width: 58.75em) {
      .plan__header-title {
        margin-bottom: 1.5rem;
        font-size: 2.25rem;
        line-height: 2.625rem; } }
  .plan__header-copy {
    margin-bottom: 1rem; }
    @media screen and (max-width: 58.75em) {
      .plan__header-copy {
        font-size: 1.125rem;
        line-height: 1.4375rem; } }
  .plan__header-cta {
    border: 0;
    color: #ff0000;
    position: relative;
    font-size: 1rem;
    background-color: transparent;
    text-decoration: none; }
    .plan__header-cta:before {
      content: '>';
      padding-right: 0.625rem;
      margin-left: -1rem;
      font-size: inherit; }
  .plan__tips {
    max-width: 37.5rem;
    margin: 0 auto;
    padding-left: 0;
    counter-reset: item;
    list-style-type: none;
    position: relative; }
  .plan__tip {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 4rem;
    border-top: 1px solid #eeeeee; }
    .plan__tip:last-of-type {
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 5rem; }
      @media screen and (max-width: 58.75em) {
        .plan__tip:last-of-type {
          margin-bottom: 2rem; } }
    .plan__tip:before {
      content: counter(item);
      counter-increment: item;
      width: 2.5rem;
      height: 2.5rem;
      border: 1px solid #eeeeee;
      border-radius: 100px;
      position: absolute;
      left: 0;
      text-align: center;
      line-height: 2.5rem; }
  .plan__tips-title {
    margin-bottom: 0.25rem; }
    @media screen and (max-width: 58.75em) {
      .plan__tips-title {
        font-size: 1.125rem;
        line-height: 1.4375rem; } }
  .plan__tips-copy {
    color: #9a9a9a;
    font-size: 1.125rem;
    line-height: 1.4375rem; }
    @media screen and (max-width: 58.75em) {
      .plan__tips-copy {
        margin-bottom: 0.25rem;
        font-size: 0.9375rem;
        line-height: 1.125rem; } }
  .plan__cta-wrapper {
    width: 100%;
    max-width: 58.75rem;
    margin: 0 auto 3.5rem auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: stretch;
        align-items: stretch;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    @media screen and (max-width: 40em) {
      .plan__cta-wrapper {
        display: block; } }
  .plan__cta {
    width: 100%;
    max-width: 28.75rem;
    padding: 1rem 1.25rem;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin: 0 auto 0.5rem auto;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    @media screen and (max-width: 40em) {
      .plan__cta {
        max-width: 100%; } }
  .plan__cta-graphic {
    width: calc(33% - 8px);
    background-color: rgba(255, 0, 0, 0.12);
    border-radius: 4px;
    position: relative; }
  .plan__cta-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  .plan__cta-content {
    width: calc(66% - 8px); }
  .plan__cta-small {
    margin-bottom: 0.25rem;
    color: #9a9a9a;
    text-transform: uppercase; }
  .plan__cta-title {
    margin-bottom: 0.25rem;
    font-weight: bold; }
  .plan__cta-body {
    font-size: 1.125rem;
    line-height: 1.4375rem; }
    @media screen and (max-width: 40em) {
      .plan__cta-body {
        font-size: 0.9375rem;
        line-height: 1.125rem; } }
  .plan__cta-link {
    margin-top: 0.5rem;
    margin-left: 1.25rem;
    border-bottom: 0;
    color: #ff0000;
    position: relative;
    text-decoration: none; }
    .plan__cta-link:before {
      content: '>';
      padding-right: 0.625rem;
      margin-left: -1rem;
      font-size: inherit; }
  .plan__some {
    width: calc(100% + 40px);
    margin-left: -1.25rem;
    margin-bottom: 3.5rem;
    padding: 2rem 2rem 2.5rem 2rem;
    background-color: #eeeeee;
    clear: both;
    overflow: hidden; }
  .plan__some-wrapper {
    width: 100%;
    max-width: 21.25rem;
    margin: 0 auto;
    position: relative; }
  .plan__some-text {
    margin-bottom: 1.5rem;
    text-align: center; }
  .plan__some-item {
    width: 50%;
    float: left;
    text-align: center; }
    .plan__some-item:last-of-type {
      float: right; }
  .plan__some-link {
    text-decoration: none;
    border: none;
    color: #333333; }
  .plan__some-img {
    margin-right: 0.625rem; }
  .plan__some-label {
    font-weight: bold; }
  .plan__section-title {
    max-width: 900px;
    margin: 0 auto 1.5rem auto; }
    @media screen and (max-width: 58.75em) {
      .plan__section-title {
        font-size: 0.9375rem;
        line-height: 1.125rem; } }
  .plan__recommendations {
    max-width: 58.75rem;
    height: 16.875rem;
    margin: 0 auto;
    white-space: nowrap;
    display: -ms-flexbox;
    display: flex; }
    @media screen and (max-width: 58.75em) {
      .plan__recommendations {
        width: calc(100% + 40px);
        margin-left: -1.25rem; } }
  .plan__recommendations-inner {
    width: 100%;
    overflow: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    -webkit-scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; }
    @media screen and (max-width: 58.75em) {
      .plan__recommendations-inner {
        padding-left: 1rem;
        padding-right: 1rem;
        animation: 0.5s ease-out 2s canSlide; } }
  .plan__recommendation {
    width: 18rem;
    height: 11.875rem;
    padding: 1rem 1.25rem;
    margin-right: 0.5rem;
    border-radius: 4px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
    background-color: white;
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    position: relative; }
    @media screen and (max-width: 58.75em) {
      .plan__recommendation {
        width: calc(100vw - 48px);
        scroll-snap-align: center; } }
  .plan__recommendation-title {
    font-weight: bold; }
  .plan__recommendation-cta {
    border-bottom: 0;
    color: #ff0000;
    position: absolute;
    bottom: 0;
    left: 2.5rem;
    bottom: 1.25rem;
    text-decoration: none; }
    .plan__recommendation-cta:before {
      content: '>';
      padding-right: 0.625rem;
      margin-left: -1rem;
      font-size: inherit; }

.scene-intro {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: relative; }
  @media screen and (max-width: 58.75em) {
    .scene-intro {
      background-position: 71% center; }
      .scene-intro:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.25; } }
  .scene-intro__title {
    width: 100%;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white; }
    @media screen and (max-width: 58.75em) {
      .scene-intro__title {
        max-width: none;
        position: absolute;
        bottom: 2.5rem; } }
  .scene-intro__title--desktop {
    max-width: 29.375rem; }
    @media screen and (max-width: 58.75em) {
      .scene-intro__title--desktop {
        max-width: none; } }

.scene-0-0 {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.scene-0-1 .figure-1,
.scene-0-1 .figure-2,
.scene-0-1 .figure-3,
.scene-0-1 .figure-4 {
  max-width: 10%;
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-0-1 .figure-1 {
  top: 33%;
  left: 24%; }

.scene-0-1 .figure-2 {
  top: 33%;
  left: 37%; }

.scene-0-1 .figure-3 {
  top: 33%;
  left: 31%; }

.scene-0-1 .figure-4 {
  top: 33%;
  left: 43%; }

.scene-0-2 .figure-1,
.scene-0-2 .figure-2,
.scene-0-2 .figure-3,
.scene-0-2 .figure-4 {
  max-width: 10%;
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-0-2 .figure-1 {
  top: 33%;
  left: 24%; }

.scene-0-2 .figure-2 {
  top: 33%;
  left: 37%; }

.scene-0-2 .figure-3 {
  top: 33%;
  left: 31%; }

.scene-0-2 .figure-4 {
  top: 33%;
  left: 43%; }

.scene-0-3 .figure-1,
.scene-0-3 .figure-2,
.scene-0-3 .figure-3,
.scene-0-3 .figure-4,
.scene-0-3 .figure-5,
.scene-0-3 .figure-6,
.scene-0-3 .figure-7,
.scene-0-3 .figure-8 {
  max-width: 10%;
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-0-3 .figure-1 {
  top: 33%;
  left: 48%; }

.scene-0-3 .figure-2 {
  top: 33%;
  left: 55%; }

.scene-0-3 .figure-3 {
  top: 33%;
  left: 60%; }

.scene-0-3 .figure-4 {
  top: 33%;
  left: 67%; }

.scene-0-3 .figure-5 {
  top: 33%;
  left: 24%; }

.scene-0-3 .figure-6 {
  top: 33%;
  left: 37%; }

.scene-0-3 .figure-7 {
  top: 33%;
  left: 31%; }

.scene-0-3 .figure-8 {
  top: 33%;
  left: 43%; }

.scene-1-0 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-1-0 .figure-1,
.scene-1-0 .figure-2,
.scene-1-0 .figure-3,
.scene-1-0 .figure-4,
.scene-1-0 .figure-5,
.scene-1-0 .figure-6,
.scene-1-0 .figure-7,
.scene-1-0 .figure-8 {
  max-width: 10%;
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-1-0 .figure-1 {
  top: 30%;
  left: 28%;
  background: url("/images/scene-1-0/coat-1.svg") no-repeat top center; }

.scene-1-0 .figure-2 {
  top: 30%;
  left: 38%;
  background: url("/images/scene-1-0/coat-2.svg") no-repeat top center; }

.scene-1-0 .figure-3 {
  top: 30%;
  left: 34%;
  background: url("/images/scene-1-0/coat-1.svg") no-repeat top center; }

.scene-1-0 .figure-4 {
  top: 30%;
  left: 43%;
  background: url("/images/scene-1-0/coat-2.svg") no-repeat top center; }

.scene-1-0 .figure-5 {
  top: 30%;
  left: 53%;
  background: url("/images/scene-1-0/coat-4.svg") no-repeat top center; }

.scene-1-0 .figure-6 {
  top: 30%;
  left: 59%;
  background: url("/images/scene-1-0/coat-3.svg") no-repeat top center; }

.scene-1-0 .figure-7 {
  top: 30%;
  left: 64%;
  background: url("/images/scene-1-0/coat-4.svg") no-repeat top center; }

.scene-1-0 .figure-8 {
  top: 30%;
  left: 71%;
  background: url("/images/scene-1-0/coat-3.svg") no-repeat top center; }

.scene-1-1 .figure,
.scene-1-2 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-1-1 .figure-1,
.scene-1-1 .figure-2,
.scene-1-1 .figure-3,
.scene-1-1 .figure-4,
.scene-1-1 .figure-5,
.scene-1-1 .figure-6,
.scene-1-1 .figure-7,
.scene-1-1 .figure-8,
.scene-1-2 .figure-1,
.scene-1-2 .figure-2,
.scene-1-2 .figure-3,
.scene-1-2 .figure-4,
.scene-1-2 .figure-5,
.scene-1-2 .figure-6,
.scene-1-2 .figure-7,
.scene-1-2 .figure-8 {
  max-width: 10%;
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-1-1 .figure-1,
.scene-1-2 .figure-1 {
  top: 30%;
  left: 28%;
  background: url("/images/scene-1-0/coat-1.svg") no-repeat top center; }

.scene-1-1 .figure-2,
.scene-1-2 .figure-2 {
  top: 30%;
  left: 38%;
  background: url("/images/scene-1-0/coat-2.svg") no-repeat top center; }

.scene-1-1 .figure-3,
.scene-1-2 .figure-3 {
  top: 30%;
  left: 34%;
  background: url("/images/scene-1-0/coat-1.svg") no-repeat top center; }

.scene-1-1 .figure-4,
.scene-1-2 .figure-4 {
  top: 30%;
  left: 43%;
  background: url("/images/scene-1-0/coat-2.svg") no-repeat top center; }

.scene-1-1 .figure-5,
.scene-1-2 .figure-5 {
  top: 30%;
  left: 48%;
  background: url("/images/scene-1-0/coat-4.svg") no-repeat top center; }

.scene-1-1 .figure-6,
.scene-1-2 .figure-6 {
  top: 30%;
  left: 54%;
  background: url("/images/scene-1-0/coat-3.svg") no-repeat top center; }

.scene-1-1 .figure-7,
.scene-1-2 .figure-7 {
  top: 30%;
  left: 59%;
  background: url("/images/scene-1-0/coat-4.svg") no-repeat top center; }

.scene-1-1 .figure-8,
.scene-1-2 .figure-8 {
  top: 30%;
  left: 64%;
  background: url("/images/scene-1-0/coat-3.svg") no-repeat top center; }

.scene-1-3 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-1-3 .figure-1,
.scene-1-3 .figure-2,
.scene-1-3 .figure-3,
.scene-1-3 .figure-4,
.scene-1-3 .figure-5 {
  max-width: 9%;
  width: 100%;
  position: absolute;
  top: 84%;
  z-index: 3; }

.scene-1-3 .figure-1 {
  left: 17%;
  background: url("/images/scene-1-3/dog-food.svg") no-repeat top center; }

.scene-1-3 .figure-2 {
  left: 27%;
  background: url("/images/scene-1-3/dog-food.svg") no-repeat top center; }

.scene-1-3 .figure-3 {
  left: 37%;
  background: url("/images/scene-1-3/dog-food.svg") no-repeat top center; }

.scene-1-3 .figure-4 {
  left: 47%;
  background: url("/images/scene-1-3/dog-food.svg") no-repeat top center; }

.scene-1-3 .figure-5 {
  left: 77%;
  background: url("/images/scene-1-3/dog-food.svg") no-repeat top center; }

.scene-1-4 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-1-4 .figure-1,
.scene-1-4 .figure-2,
.scene-1-4 .figure-3,
.scene-1-4 .figure-4,
.scene-1-4 .figure-5 {
  max-width: 7%;
  width: 100%;
  position: absolute;
  top: 84%;
  z-index: 3; }

.scene-1-4 .figure-1 {
  left: 17%;
  background: url("/images/scene-1-3/cat-food.svg") no-repeat top center; }

.scene-1-4 .figure-2 {
  left: 25%;
  background: url("/images/scene-1-3/cat-food.svg") no-repeat top center; }

.scene-1-4 .figure-3 {
  left: 33%;
  background: url("/images/scene-1-3/cat-food.svg") no-repeat top center; }

.scene-1-4 .figure-4 {
  left: 41%;
  background: url("/images/scene-1-3/cat-food.svg") no-repeat top center; }

.scene-1-4 .figure-5 {
  left: 78%;
  background: url("/images/scene-1-3/cat-food.svg") no-repeat top center; }

.scene-2-0 .figure {
  min-height: 39%; }

.scene-2-0 .figure-1,
.scene-2-0 .figure-2,
.scene-2-0 .figure-3,
.scene-2-0 .figure-4,
.scene-2-0 .figure-5,
.scene-2-0 .figure-6,
.scene-2-0 .figure-7,
.scene-2-0 .figure-8 {
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-2-0 .figure-1 {
  max-width: 9%;
  min-height: 37%;
  top: 13%;
  left: 20.32%;
  background: url("/images/scene-2-0/kitchen.svg") no-repeat top center; }

.scene-2-0 .figure-2 {
  max-width: 16.6%;
  min-height: 32%;
  top: 17.5%;
  left: 43.2%;
  background: url("/images/scene-2-0/bathroom.svg") no-repeat top center; }

.scene-2-0 .figure-3 {
  min-height: 28.9%;
  max-width: 8.5%;
  top: 16.9%;
  left: 72.3%;
  background: url("/images/scene-2-0/garden.svg") no-repeat top center; }

.scene-2-0 .figure-4 {
  max-width: 22.5%;
  min-height: 22%;
  top: 65%;
  left: 13.8%;
  background: url("/images/scene-2-0/bedroom.svg") no-repeat top left; }

.scene-2-0 .figure-5 {
  max-width: 6.74%;
  min-height: 24%;
  top: 64%;
  left: 48.41%;
  background: url("/images/scene-2-0/office.svg") no-repeat top left; }

.scene-2-0 .figure-6 {
  max-width: 18.2%;
  min-height: 21%;
  top: 66.1%;
  left: 67.5%;
  background: url("/images/scene-2-0/living-room.svg") no-repeat top center; }

.scene-2-1 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-2-1 .figure-1,
.scene-2-1 .figure-2 {
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-2-1 .figure-1 {
  max-width: 14%;
  top: 60%;
  left: 18.5%;
  background: url("/images/scene-2-1/extension.svg") no-repeat top center; }

.scene-2-1 .figure-2 {
  max-width: 20%;
  top: 51%;
  left: 46%;
  background: url("/images/scene-2-1/renovation.svg") no-repeat top center; }

.scene-2-2 .figure {
  max-width: 21%;
  height: 41%;
  min-height: 4.5625rem; }

.scene-2-2 .figure-1,
.scene-2-2 .figure-2,
.scene-2-2 .figure-3 {
  max-width: 14.89%;
  width: 100%;
  position: absolute;
  top: 31.9%;
  z-index: 3; }

.scene-2-2 .figure-1 {
  left: 22.95%;
  background: url("/images/scene-2-2/window-1.svg") no-repeat top left; }

.scene-2-2 .figure-2 {
  left: 42.75%;
  background: url("/images/scene-2-2/window-2.svg") no-repeat top left; }

.scene-2-2 .figure-3 {
  left: 62%;
  background: url("/images/scene-2-2/window-3.svg") no-repeat top left; }

.scene-3-0 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-3-0 .figure-1,
.scene-3-0 .figure-2,
.scene-3-0 .figure-3,
.scene-3-0 .figure-4,
.scene-3-0 .figure-5,
.scene-3-0 .figure-6,
.scene-3-0 .figure-7,
.scene-3-0 .figure-8 {
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-3-0 .figure-1 {
  max-width: 9%;
  top: 12.5%;
  left: 30.5%;
  background: url("/images/scene-3-0/kitchen.svg") no-repeat top center; }

.scene-3-0 .figure-2 {
  max-width: 8%;
  top: 17%;
  left: 62%;
  background: url("/images/scene-3-0/garden.svg") no-repeat top center; }

.scene-3-0 .figure-3 {
  max-width: 23%;
  top: 65%;
  left: 24%;
  background: url("/images/scene-3-0/bedroom.svg") no-repeat top center; }

.scene-3-0 .figure-4 {
  max-width: 19%;
  top: 66.5%;
  left: 56.5%;
  background: url("/images/scene-3-0/living-room.svg") no-repeat top center; }

.scene-3-1 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-3-1 .figure-1,
.scene-3-1 .figure-2,
.scene-3-1 .figure-3,
.scene-3-1 .figure-4 {
  max-width: 4%;
  width: 100%;
  position: absolute;
  left: 54.5%;
  z-index: 3; }

.scene-3-1 .figure-1 {
  top: 71%;
  background: url("/images/scene-3-1/mark.svg") no-repeat top center; }

.scene-3-1 .figure-2 {
  top: 60%;
  background: url("/images/scene-3-1/mark.svg") no-repeat top center; }

.scene-3-1 .figure-3 {
  top: 54%;
  background: url("/images/scene-3-1/mark.svg") no-repeat top center; }

.scene-3-1 .figure-4 {
  top: 65%;
  background: url("/images/scene-3-1/mark.svg") no-repeat top center; }

.scene-3-2 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-3-2 .figure-1,
.scene-3-2 .figure-2,
.scene-3-2 .figure-3,
.scene-3-2 .figure-4 {
  max-width: 4%;
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-3-2 .figure-1 {
  max-width: 13%;
  top: 74.5%;
  left: 54%;
  background: url("/images/scene-3-2/cleaning-1.svg") no-repeat top center;
  background-size: contain; }

.scene-3-2 .figure-2 {
  max-width: 6.5%;
  top: 74%;
  left: 34%;
  background: url("/images/scene-3-2/cleaning-2.svg") no-repeat top center; }

.scene-3-2 .figure-3 {
  max-width: 4%;
  top: 77.5%;
  left: 48%;
  background: url("/images/scene-3-2/cleaning-3.svg") no-repeat top center; }

.scene-3-2 .figure-4 {
  max-width: 13%;
  top: 75.5%;
  left: 19%;
  background: url("/images/scene-3-2/cleaning-4.svg") no-repeat top center; }

.scene-3-3 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-3-3 .figure-1,
.scene-3-3 .figure-2,
.scene-3-3 .figure-3,
.scene-3-3 .figure-4,
.scene-3-3 .figure-5,
.scene-3-3 .figure-6,
.scene-3-3 .figure-7,
.scene-3-3 .figure-8 {
  max-width: 4%;
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-3-3 .figure-1 {
  max-width: 10%;
  top: 28%;
  left: 48%;
  background: url("/images/scene-3-3/candels-1.svg") no-repeat top center; }

.scene-3-3 .figure-2 {
  max-width: 43%;
  top: 31%;
  left: 18%;
  background: url("/images/scene-3-3/candels-2.svg") no-repeat top center; }

.scene-3-3 .figure-3 {
  max-width: 7%;
  top: 34%;
  left: 22%;
  background: url("/images/scene-3-3/candels-3.svg") no-repeat top center; }

.scene-3-3 .figure-4 {
  max-width: 10%;
  top: 53%;
  left: 58%;
  background: url("/images/scene-3-3/candels-4.svg") no-repeat top center; }

.scene-3-4 .figure {
  height: 100%;
  min-height: 4.5625rem; }

.scene-3-4 .figure-1,
.scene-3-4 .figure-2,
.scene-3-4 .figure-3,
.scene-3-4 .figure-4,
.scene-3-4 .figure-5,
.scene-3-4 .figure-6,
.scene-3-4 .figure-7,
.scene-3-4 .figure-8 {
  max-width: 4%;
  width: 100%;
  position: absolute;
  z-index: 3; }

.scene-3-4 .figure-1 {
  max-width: 19%;
  top: 74%;
  left: 61%;
  background: url("/images/scene-3-4/fresh-air-1.svg") no-repeat top center; }

.scene-3-4 .figure-2 {
  max-width: 7%;
  top: 35%;
  left: 71%;
  background: url("/images/scene-3-4/fresh-air-2.svg") no-repeat top center; }

.scene-3-4 .figure-3 {
  max-width: 20%;
  top: 35%;
  left: 64%;
  background: url("/images/scene-3-4/fresh-air-3.svg") no-repeat top center; }

.scene-3-4 .figure-4 {
  max-width: 20%;
  top: 64.5%;
  left: 61%;
  background: url("/images/scene-3-4/fresh-air-4.svg") no-repeat top center; }

.hidden {
  display: none; }

.u-img-link {
  border: 0; }

.sr-only {
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  position: absolute; }

.u-hide-mobile {
  display: block; }
  @media screen and (max-width: 40em) {
    .u-hide-mobile {
      display: none; } }

.u-hide-desktop {
  display: none; }
  @media screen and (max-width: 40em) {
    .u-hide-desktop {
      display: block; } }

.u-offset-right {
  left: 100%; }

.u-offset-left {
  left: -100%; }
